// Here you can add other styles
/* CustomCalendar.css */

/* Style the calendar container */
.custom-calendar {
  font-family: Arial, sans-serif;
}

/* Style the calendar header */
.custom-calendar .rbc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Style the previous and next buttons */
.custom-calendar .rbc-btn-group {
  display: flex;
}

.custom-calendar .rbc-btn-group button {
  cursor: pointer;
  padding: 5px;
  background-color: #fff;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  color: #4286f4;
}

/* Style the month label */
.custom-calendar .rbc-toolbar-label {
  font-weight: bold;
  font-size: 20px;
}

/* Style the weekdays row */
.custom-calendar .rbc-row-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Style the weekday label */
.custom-calendar .rbc-row-header .rbc-header {
  font-weight: bold;
  width: calc(100% / 7);
  text-align: center;
}

/* Style the days */
.custom-calendar .rbc-month-view {
  display: flex;
  flex-wrap: wrap;
}

/* Style each day cell */
.custom-calendar .rbc-month-view .rbc-month-row > * {
  //   width: calc(100% / 7);
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

/* Style the today cell */
.custom-calendar .rbc-month-view .rbc-today {
  background-color: #f0f0f0;
  //   border-radius: 50%;
}

/* Style the selected cell */
.custom-calendar .rbc-month-view .rbc-selected {
  background-color: #4286f4;
  color: #fff;
  //   border-radius: 50%;
}

/* Style the event container */
.custom-calendar .rbc-event {
  background-color: #4286f4;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

/* Style the event title */
.custom-calendar .rbc-event-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style the event time */
.custom-calendar .rbc-event-label {
  display: none; /* Remove if you want to display event time */
}

/* Style the selected day */
.custom-calendar-selected {
  background-color: #cfe4fa;
  color: #fff;
}

//Modal

.event-modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-modal {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  max-width: 400px;
  min-width: 300px;

  max-height: 400px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.event-modal .modal-title {
  font-size: 18px;
  font-weight: bold;
}

.event-modal .modal-close-button {
  background: none;
  border: none;
  color: #8c8c8c;
  font-size: 18px;
  cursor: pointer;
}

.event-modal .modal-content {
  font-size: 14px;
  color: #333;
}

.event-modal .modal-details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.event-modal .modal-details .icon-clock,
.event-modal .modal-close-button .icon-cross {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: contain;
}

.event-modal .modal-details .icon-clock {
  background-image: url('https://example.com/clock-icon.png');
}

.event-modal .modal-close-button .icon-cross {
  background-image: url('https://example.com/cross-icon.png');
}

.event-modal .modal-date {
  margin: 0;
}

.event-modal .modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.event-modal .edit-button {
  padding: 8px 16px;
  background-color: transparent;
  color: green;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.event-modal .delete-button {
  padding: 8px 16px;
  background-color: transparent;
  color: red;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar {
  background-color: #4286f4;
}

/* width */
.sidebar-nav::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebar-nav::-webkit-scrollbar-track {
  background: #4286f4;
}

/* Handle */
.sidebar-nav::-webkit-scrollbar-thumb {
  background: white;
}

/* Handle on hover */
.sidebar-nav::-webkit-scrollbar-thumb:hover {
  background: white;
}
